<template>
  <v-container>
    <div>
      <h1> <textloader-component textKey="UthyrningRubrik"/> </h1>
      <paragraph-component
        :src="require('@/assets/uthyrning/boombox.png')">
       <textloader-component textKey="UthyrningBrödtext"/>
      </paragraph-component>
    </div>

    <v-row>
      <v-col
        cols="12"
        sm="6"
        class="text-center"
      >
        <v-date-picker
          v-model="dates"
          range
          full-width
          @click="print"
        ></v-date-picker>
      </v-col>
      <v-col
        cols="12"
        sm="6"
      >
        <v-text-field
          v-model="dateRangeText"
          label="Date range"
          prepend-icon="mdi-calendar"
          readonly
        ></v-text-field>
        <v-text-field
          v-model="name"
          filled
          label="FullName"
        ></v-text-field>
        <v-btn
          block
          @click="checkInput"
        >
          Continue
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ParagraphComponent from '@/components/ParagraphComponent'
import TextloaderComponent from '@/components/TextloaderComponent'
const axios = require('axios')

export default {
  components: {
    ParagraphComponent,
    TextloaderComponent
  },
  data: () => ({
    today: new Date().toLocaleDateString('sv-SE', { day: '2-digit' }).split('/').reverse().join('-'),
    dates: ['', ''],
    name: '',
    range_limit: 3,
    diff: 0
  }),
  computed: {
    dateRangeText () {
      return this.dates.join(' ~ ')
    }
  },
  methods: {
    print () {
      console.log('log')
    },
    addDays (date, days) {
      var result = new Date(date)
      result.setDate(result.getDate() + days)
      return result
    },
    checkInput () {
      console.log(this.today)

      var splitDateStart = this.dates[0].split('-')
      var splitDateEnd = this.dates[1].split('-')
      var start = new Date(splitDateStart[0], splitDateStart[1], splitDateStart[2])
      var end = new Date(splitDateEnd[0], splitDateEnd[1], splitDateEnd[2])
      this.diff = Math.abs((end - start) * 1.1574E-8) // milisecond to days conversion
      console.log(Math.round(this.diff))

      if (this.diff > this.range_limit) {
        var limitDate = this.addDays(start, this.range_limit)
        var dateFormat = [limitDate.getFullYear(), limitDate.getMonth(), limitDate.getDate()]

        if (dateFormat[2].toString().length === 1) {
          dateFormat[2] = '0' + dateFormat[2]
        }
        if (dateFormat[1].toString().length === 1) {
          dateFormat[1] = '0' + dateFormat[1]
        }

        this.dates = [this.dates[0], dateFormat.join('-')]
      }
      this.validate()
      console.log(this.dates)

      // Check if start is before end. You have to book forward,
      // no booking for example 2022-11-10 to 2022-11-01.
    },
    validate () {
      // Send name and date(s) to backend
      axios
        .post('/api/uthyrning/create', {
          name: this.name,
          start_date: this.dates[0],
          end_date: this.dates[1],
          cancelled: false
        })
        .then((response) => {
          console.log(response.data.message)
          // Hantera status från servern
          if (response.data.message === 'Booking failed') {
            this.snackMsg = 'Bokning misslyckades'
          } else if (response.data.message === 'Booking Created') {
            this.snackMsg = 'Bokning tillagd i databasen!'
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }

  }
}
</script>
